<template>
  <div id="page-top">
   
    <router-view />
<div id="browser-disclaimer">

    <div class="container alert alert-info alert-dismissible fade show text-center disclaimer-box" 
    role="alert"
    >
      <p>This Site is best viewed in</p> 
        <div class="d-flex justify-content-around disclaimer">
              <em class="fab fa-chrome disclaimer-logo"></em>
              <em class="fab fa-firefox-browser disclaimer-logo"></em>
              <em class="fab fa-edge disclaimer-logo"></em>
        </div>
         
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true" class="close-btn" @click="browserConsentLock">&times;</span>
      </button>
    </div>
</div>
    <div id="cookie-consent" class="container alert alert-primary alert-dismissible fade show cookie" role="alert">
      <div class="text">
        This website uses cookies to ensure you get the best experience on our website.
      </div>
      <div class="close-btn d-flex justify-content-end">
        <button type="button" class="btn mx-0" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true" class="cookie-consent" @click="cookieConsentLock">OK</span>
        </button>
      </div>
    </div>
    <a class="border rounded d-inline scroll-to-top" href="#page-top"
      ><em class="fas fa-angle-up"></em
    ></a>
  </div>
</template>

<script>
export default {
 mounted()
 {
   let safariAgent = window.navigator.userAgent.indexOf("Safari") > -1;
   let chromeAgent = window.navigator.userAgent.indexOf("Chrome") > -1;
   let IExplorerAgent = window.navigator.userAgent.indexOf("MSIE") > -1 || window.navigator.userAgent.indexOf("rv:") > -1;
   let firefoxAgent = window.navigator.userAgent.indexOf("Firefox") > -1;
  

   if(IExplorerAgent || firefoxAgent )
   {
      document.getElementById('browser-disclaimer').style.display = 'none';
   }
   else if( chromeAgent&&safariAgent )
   {
      document.getElementById('browser-disclaimer').style.display = 'none';
   }
   else
   {
      document.getElementById('browser-disclaimer').style.display = 'block';
   }

   if(localStorage.getItem('cookieC-accepted'))
   {
     document.getElementById('cookie-consent').style.display = 'none';
   }
   else
   {
     document.getElementById('cookie-consent').style.display = 'block';
   }
   if(localStorage.getItem('browserC-accepted'))
   {
     document.getElementById('browser-disclaimer').style.display = 'none';
   }
   
 },
 methods:
 {
   cookieConsentLock()
   {
     localStorage.setItem('cookieC-accepted',true)
   },
   browserConsentLock()
   {
     localStorage.setItem('browserC-accepted',true)
   }
 }
};
</script>
<style>
#app {
  width: 100%;
  background-color: #00A5B1;
  color: #2c3e50;
  float: left;
}
.disclaimer-box
{
  position: fixed;
  width: 30%;
  height: 20%;
  z-index: 5;
  bottom:23%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  color: #2c3e50;
  border: gray 1px dotted;
}
.close-btn
{
  color: darkblue;
  font-size: 33px;
}
.disclaimer
{
  width: 40%;
  margin: 0 auto;
  padding-top:10px ;
}
.disclaimer-logo
{
  font-size: 25px;
  color: #27588D;
}
.cookie-consent
{
  border-radius: 10px;
  background-color: #27588D;
  color: #fff;
  padding:4px 5px 4px 5px;
  font-size: 20px;
  letter-spacing: 2px;
}
.cookie button:hover, button:focus
{
  outline: none;
}
.cookie
{
  background-color: #fff;
  color: #2c3e50;
  border: gray 1px dotted;
  width: 30%;
  height: 20%;
  position:fixed;
  z-index: 5;
  bottom: 1%;
  right: 0;
}
.backdrop {
  background: transparent;
  height: 100%;
}
.logo {
  height: 60px;
  align-self: center;
}
.back {
  margin: 0px;
  padding: 20px;
  margin-right: 0px;
}
.image-left {
  width: 100%;
  height: auto;
  place-self: center;
  padding-left: 20px;
  align-items: center;
  align-self: center;
}
.image-box {
  display: grid;
}
.theme-area-head
{
  background-color: #00A5B1;
}
.theme-tbl-head
{
  background-color: #00A5B1;
}
.theme-btn
{
  background-color: #00A5B1;
}
.theme-special-btn
{
  background-color: #FF6C2E;
}
.theme-delAcc
{
  background-color: #27588D;
}
@media only screen and (max-width: 785px) {
  .cookie, .disclaimer-box
  {
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 21%;
  }
}
</style>
