import axios from 'axios'
import JwtService from "@/api/jwt.service";
import { API_URL } from "@/api/config";
import encrypter from "object-encrypter"

var engine = encrypter(process.env.VUE_APP_EK, { ttl: true });

const ApiService = {
    init() {
        axios.defaults.baseURL = API_URL;
    },

    setHeader() {
        axios.defaults.headers.common[
            "Authorization"
        ] = `${JwtService.getToken()}`;
    },

   

    get(resource, slug = "") {
        return axios.get(`${resource}/${slug}`).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    post(resource, params) {
        if (params) {
            var hex = engine.encrypt(params, 100000);
            return axios.post(`${resource}`, { "data": hex });
        } else {
            return axios.post(`${resource}`);
        }

    },
    postNotice(resource, params) {
        return axios.post(`${resource}`, params);
    },
    postForResponseTypeBlob(resource, params) {
        if (params) {
            var hex = engine.encrypt(params, 100000);
            return axios.post(`${resource}`, { "data": hex }, { responseType: 'blob' });
        } else {
            return axios.post(`${resource}`);
        }
    },

   
}
export default ApiService;

