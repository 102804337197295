import ApiService from "@/api/api.service";
import JwtService from "@/api/jwt.service";
import { post, Service } from "@/api/service";

import {
    LOGIN,
    SET_USER,
    SET_TOKEN,
    LOGOUT,
    FORGOT,
    REGISTER,
    CHECK_AUTH,
    SET_DELVERY_ACCOUNT_ITEM,
    UPDATE_USER,
    LIST_NOTIFICATIONS,
    VALIDATE,
    SET_ALL_DELIVERY_ACCOUNT_ITEM,
    RESET
} from "../actions.type";
import {
    SET_AUTH,
    SET_DELVERY_ACCOUNT,
    SET_ALL_DELIVERY_ACCOUNT,
    PURGE_AUTH,
    SET_ERROR,
    TEMP_USER,
    UPDATE_PROFILE_IMAGE
} from "../mutations.type";

const state = {
    errors: null,
    user: {},
    tempUser: {},
    isAuthenticated: !!JwtService.getToken(),
    count: 0
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    tempUser(state) {
        return state.tempUser;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    planDetails(state) {
        return state.user.planDetails;
    },
    selectedDeliveryAccount(state) {
        return state.deliveryAccount;
    },
    allDeliveryAccountItems(state) {
        return state.allDeliveryAccount;
    }

};

const actions = {
    [SET_TOKEN](context,token)
    {
        JwtService.saveToken(token)
    },
    [SET_USER](context, user) {
        //JwtService.saveToken(user.authToken);
        context.commit(SET_AUTH, user)
    },
    [SET_DELVERY_ACCOUNT_ITEM](context, deliveryAccount) {
        context.commit(SET_DELVERY_ACCOUNT, deliveryAccount)
    },
    [SET_ALL_DELIVERY_ACCOUNT_ITEM](context, allDeliveryAccounts) {
        context.commit(SET_ALL_DELIVERY_ACCOUNT, allDeliveryAccounts)
    },
    [LIST_NOTIFICATIONS](context, body) {

        return new Promise((resolve, reject) => {
            post("v2/listNotifications", body)
                .then((data) => {
                    // context.commit(SET_NOTIFICATIONS, data.data.data);
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.message);
                    reject(response)
                });
        })
    },

    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            post("v2/login", credentials)
                .then((data) => {
                    if (data.status == 'success') {
                        if (data.data) {
                            if (data.data.companyaccounts.length > 1) {
                                context.commit(TEMP_USER, data.data);
                            }
                        }
                        resolve(data);
                    } else {
                        resolve(data);
                    }
                })
                .catch(({ response }) => {
                    reject(response);
                    context.commit(SET_ERROR, response.message);
                });
        })
    },
    [RESET](context, credentials) {
        return new Promise((resolve, reject) => {
            post("resetPassword", credentials)
                .then((data) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                    context.commit(SET_ERROR, response.message);
                });
        })
    },

    [VALIDATE](context, credentials) {
        return new Promise((resolve, reject) => {
            post("v2/checkUseraccount", credentials)
                .then((data) => {
                    context.commit(TEMP_USER, credentials);
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },

    [LOGOUT](context, params) {
        return new Promise((resolve, reject) => {
            Service.get("logout", params)
                .then((data) => {
                    resolve(data);
                    context.commit(PURGE_AUTH);
                })
                .catch((response) => {
                    reject(response)
                    context.commit(SET_ERROR, response.message);
                });
        })

    },
    [REGISTER](context, credentials) {
        return new Promise((resolve, reject) => {
            post("users", { credentials })
                .then((data) => {
                    context.commit(SET_AUTH, data.user);
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.message);
                    reject(response);
                });
        });
    },

    [FORGOT](context, params) {
        return new Promise((resolve, reject) => {
            post("forgotPassword", params)
                .then((data) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                    context.commit(SET_ERROR, response.message);
                });
        })
    },

    [CHECK_AUTH](context) {
        if (JwtService.getToken()) {
            Service.get("user")
                .then((data) => {
                    context.commit(SET_AUTH, data.user);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.message);
                });
        } else {
            context.commit(PURGE_AUTH);
        }
    },
    [UPDATE_USER](context, payload) {
        const { email, username, password, image, bio } = payload;
        const user = {
            email,
            username,
            bio,
            image
        };
        if (password) {
            user.password = password;
        }

        return ApiService.put("user", user).then(({ data }) => {
            context.commit(SET_AUTH, data.user);
            return data;
        });
    }
};

const mutations = {
    increment(state) {
        state.count++
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true;
        state.user = user;
        state.errors = {};
        JwtService.saveToken(state.user.authToken);
    },
    [SET_DELVERY_ACCOUNT](state, deliveryAccount) {
        state.deliveryAccount = deliveryAccount;
    },
    [SET_ALL_DELIVERY_ACCOUNT](state, allDeliveryAccount) {
        state.allDeliveryAccount = allDeliveryAccount;
    },
    [TEMP_USER](state, tempUser) {
        state.tempUser = tempUser;
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        JwtService.destroyToken();
    },
    [UPDATE_PROFILE_IMAGE](state, image) {
        state.user.profileImage = image;
        state.errors = {};
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};