import firebase from '@firebase/app'
import '@firebase/messaging'
// import other firebase stuff...

const firebaseConfig = {
    apiKey: "AIzaSyB32xi1xMt4gYG8f6ttYZ7JMMKWX_w5inQ",
    authDomain: "one-notice678.firebaseapp.com",
    projectId: "one-notice678",
    storageBucket: "one-notice678.appspot.com",
    messagingSenderId: "734603282717",
    appId: "1:734603282717:web:ef0fbd9ea6b48bb4bbb9f4",
    measurementId: "G-0H4GPXPDRC"
}

// firebase.initializeApp(firebaseConfig)


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)

} else {
    firebase.app(); // if already initialized, use that one
}
export default firebase