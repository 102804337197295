<template>
  <nav
    class="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top"
    v-if="currentUser && currentUser.userRole"
  >
    <Loading
      :active="isLoading"
      :can-cancel="true"
      
      :is-full-page="fullPage"
    ></Loading>
    <div class="container-fluid">
      <button
        class="btn btn-link d-md-none rounded-circle mr-3"
        id="sidebarToggleTop"
        type="button"
      >
        <em class="fas fa-bars"></em>
      </button>
      
          <div  v-if=" currentUser.userRole != 'Super Admin'">
          <div class="nav-item dropdown" id="dropdown" v-if=" allDeliveryAccountItems?.length > 1  ">
            <a
              class="dropdown-toggle nav-link"
              style="min-width: 200px;"
              data-toggle="dropdown"
              aria-expanded="false"
              href="#">
              <div  v-if="currentDeliveryAccount.Deliveryaccount_Alias != 'All' ">
              <span v-if="currentDeliveryAccount.Deliveryaccount_Alias" class="d-none d-lg-inline mr-2 text-gray-600 small">{{currentDeliveryAccount.Deliveryaccount_Alias}} - </span>
              <span class="d-none d-lg-inline mr-2 text-gray-600 small">{{currentDeliveryAccount.Deliveryaccount}}</span>
              </div>
              <div  v-else>
              <span class="d-none d-lg-inline mr-2 text-gray-600 small">&nbsp;&nbsp;&nbsp;All Accounts&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </div>
            </a>
            <div
              class="dropdown-menu shadow dropdown-menu-right animated--grow-in d-down"
            >
            <div v-for="deliveryAccount in allDeliveryAccountsList"  v-bind:key="deliveryAccount">
                <a class="dropdown-item"  v-on:click="setDevilveryAccount(deliveryAccount)">
                  {{deliveryAccount.Deliveryaccount_Alias}} {{deliveryAccount.Deliveryaccount}}</a>
            </div>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" v-on:click="setDevilveryAccount(allAccounts)">
                 {{allAccounts.Deliveryaccount_Alias}} Accounts

               </a>
            </div>
          </div>
          </div>

      <ul class="nav navbar-nav flex-nowrap ml-auto" >
        <li class="nav-item dropdown d-sm-none no-arrow">
          <a
            class="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="false"
            href="#"
            ><em class="fas fa-search"></em
          ></a>
        </li>
        <li class="nav-item dropdown no-arrow mx-1 " >

          <div class="nav-item dropdown no-arrow "  v-click-outside="clickOutsideNotifications">
            <a
              class=" nav-link tooltip1"

                v-on:click="fetchNotifications(); openNotification()"
              href="#"
              ><span class="badge badge-danger badge-counter" v-if="notificationCount >0">{{
                notificationCount
              }}</span
              ><em class="fas fa-bell fa-fw"></em
            >
              <span class="tooltiptext">Notifications</span>
            </a>

            <div

              v-if="showNotifications"
              style="display:block"
              class="dropdown-menu dropdown-menu-right dropdown-list dropdown-menu-right animated--grow-in"
            >
              <h6 v-if="notifications?.length > 0" class="dropdown-header">
                alerts center
              </h6>
              <div style="overflow-y: scroll;
                height: 400px;"
                 v-if="notifications?.length > 0" >
              <a
                class="d-flex align-items-center dropdown-item"
                v-for="item in notifications"
                v-on:click="updateNotification(item)"
                v-bind:key="item.id"
                v-bind:class="{ blur: item.read }"
                href="#"
              >
                <div class="mr-3">
                  <div class="bg-primary icon-circle">
                    <em class="fas fa-file-alt text-white"></em>
                  </div>
                </div>
                <div style="width:220px;word-wrap:break-word;">
                  <span class="small text-gray-500">{{
                    moment(item.createdAt).format("YYYY, MMM - DD hh:mm A")
                  }}</span>
                  <p>{{ item.message }}</p>
                </div>
              </a>
              <h6  class="load-more-notifications"  v-on:click="loadmoreNotification()">
               Load more
              </h6>
              </div>
            </div>
          </div>
        </li>

        <div class="d-none d-sm-block topbar-divider"></div>
        <li class="nav-item dropdown no-arrow">
          <div class="nav-item dropdown no-arrow">
            <a
              class="dropdown-toggle nav-link"
              data-toggle="dropdown"
              aria-expanded="false"
              href="#"
              ><span class="d-none d-lg-inline mr-2 text-gray-600 small">{{
                currentUser.firstName + " " + currentUser.lastName
              }}</span
              ><img
                v-if="currentUser.profileImage"
                class="border rounded-circle img-profile"
                :src="getS3Image"
                alt="profileImg"
              />
             
              <div
              v-else
              class="profile-default">
                <p
                class="profile-default-p">
                  {{
                    profileDefault
                  }}
                </p>
              </div>
            </a>
            <div
              class="dropdown-menu shadow dropdown-menu-right animated--grow-in"
            >
              <a class="dropdown-item" href="#" v-on:click="showProfile"
                ><em class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></em
                >&nbsp;Profile</a
              >
             
              <a class="dropdown-item" href="#" v-on:click="activityLog"
                ><em class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></em
                >&nbsp;Activity log</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" v-on:click="onLogout"
                ><em
                  class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"
                ></em
                >&nbsp;Logout</a
              >
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import {
  LOGOUT,
  LIST_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  GET_IMAGE,
  GET_DELIVERY_ACCOUNTS,SET_DELVERY_ACCOUNT_ITEM,SET_ALL_DELIVERY_ACCOUNT_ITEM,UNREAD_NOTIFICATION_COUNT
} from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import firebase from "../plugins/firebase";

import JwtService from "@/api/jwt.service";
import moment from "moment";
export default {
  name: "Header",
  components: { Loading },
  computed: {
    ...mapGetters(["currentUser","getS3Image","selectedDeliveryAccount","allDeliveryAccountItems"]),
    profileDefault()
    {
      let initials = '';
      if(JSON.stringify(this.currentUser)!='{}')
      {
        this.currentUser.lastName[0] ?
          initials = this.currentUser.firstName[0] +''+ this.currentUser.lastName[0]
          :
          initials = this.currentUser.firstName[0];
      }

      return initials
    }
  },
  props:{
    checkUpdate:String
  },
 
  mounted() {
     this.getNotificationAlert();
  },
  beforeMount(){
    this.getProfileImage();
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      offset:0,
      limit:5,
      showNotifications:false,
      notifications: [],
      notificationCount: "",
      allDeliveryAccountsList:undefined,
      currentDeliveryAccount:undefined,
      allAccounts:{ Deliveryaccount_id: "", Deliveryaccount: "", Deliveryaccount_Alias: "All" },
    };
  },
  created() {
    
 this.getNotificationsUnreadCount();
 if(this.currentUser.userRole != 'Super Admin')
   { this.getDeliveryAccount();
    this.currentDeliveryAccount=this.selectedDeliveryAccount;}
    
  },

  methods: {
    clickOutsideNotifications(){
      if(this.showNotifications==true)

       {this.showNotifications=false;}

    },
    getProfileImage(){
      let body = {
        userId: this.currentUser.userId
      }
      this.$store
        .dispatch(GET_IMAGE, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    
    moment: function(date) {
      return moment(date);
    },

    updateNotification(notification) {
      this.isLoading = true;
      let body = {
        notificationId: notification.id,
        read: true,
      };
      this.$store
        .dispatch(UPDATE_NOTIFICATION, body)
        .then((data) => {
          if (data.status != "failure"){ this.limit=5;
          this.offset=0;
          this.notifications= [];

          this.getNotifications();
           this.getNotificationsUnreadCount();
          if(this.$route.name == "Onebox")
              {

                this.$emit("notification-alert")
              }
              else
              {
                this.$router.push({ name: "Onebox" });
              }}
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    loadmoreNotification()
    {
      this.offset =0;
      this.limit=this.limit + 5;
       this.getNotifications();
    },
    openNotification()
    {
      this.showNotifications=!this.showNotifications;
    },
    fetchNotifications()
    {
       this.limit = 5
       this.offset = 0
       this.getNotifications()
    },
    getNotifications() {
      this.isLoading = true;
       let body = {

           userdetails_id: this.$cookie.getCookie("userdetails_id"),
    offset : this.offset ,
    limit :  this.limit
      };
      
      this.$store
        .dispatch(LIST_NOTIFICATIONS,body)
        .then((data) => {
          //concat? it causes duplication of notification -- this.notifications= this.notifications.concat(data.data);
           this.notifications = data.data
            this.isLoading = false;
          
        }
        )
        .catch(() => {
          this.isLoading = false;
        });
    },
    getNotificationsUnreadCount() {
      this.isLoading = true;
       let body = {

           userdetails_id: this.$cookie.getCookie("userdetails_id"),

      };
      this.$store
        .dispatch(UNREAD_NOTIFICATION_COUNT,body)
        .then((data) => {

            this.notificationCount=data.data.unreadCount;
            this.isLoading = false;
          
        }
        )
        .catch(() => {
          this.isLoading = false;
        });
    },
    getNotificationAlert(){
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {

         this.$emit("notification-alert", payload);
         this.getNotificationsUnreadCount();
            this.showNotifications=false,
            this.notifications= [];
        
      });
    },
    onLogout() {
      this.isLoading = true;
      let body = {
        registrationToken: localStorage.getItem('fcm_token')
      };
      this.$store
        .dispatch(LOGOUT,body)
        .then(() => {
          this.isLoading = false;
          localStorage.removeItem('fcm_token')
          this.$router.replace({ name: "Login" });
          JwtService.destroyToken();
          localStorage.clear();
        })
        .catch(() => {
          JwtService.destroyToken();
          localStorage.clear();
          this.$router.replace({ name: "Login" });
          this.isLoading = false;
        });
    },
    showProfile() {
      this.$router.replace({ name: "Profile" });
    },
    activityLog() {
       this.$router.push({ name: "ActivityLog" });
    },
setDevilveryAccount(deliveryAccount)
{
    this.$store.dispatch(SET_DELVERY_ACCOUNT_ITEM, deliveryAccount);
  this.currentDeliveryAccount=deliveryAccount;
  this.$emit("change-delivery", deliveryAccount)
},
    getDeliveryAccount() {
      let body = {
       companyId: this.$cookie.getCookie("COMPANY_ID"),
        userdetails_id: this.$cookie.getCookie("userdetails_id")
      };
      this.$store
        .dispatch(GET_DELIVERY_ACCOUNTS, body)
        .then((data) => {
          if (Array.isArray(data.data)) {
            this.allDeliveryAccountsList=data.data;
            this.$store.dispatch(SET_ALL_DELIVERY_ACCOUNT_ITEM, data.data);

          }

        })
        .catch(() => 
        {
          //intentional - catch
        });
    },
  },
};
</script>

<style scoped  lang="scss">
 /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #1f88d6 #e0e0e0;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 15px;
  }

  *::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #1f88d6;
    border-radius: 18px;
    border: 6px none #ffffff;
  }
.profile-default
{
  border: 2px solid #d14f52;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 19px;
  font-weight: bolder;
  background-color: #27588d;
  color: #fff;
}
.profile-default-p
{
  text-align: center;
  line-height: 35px;
}
.blur {
  opacity: 0.5;
}
.load-more-notifications
{
      text-align: center;
    cursor: pointer;
    background-color: #b0b8ce;
    border: 1px solid #fff;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #fff;
    margin: 0px;
    &:hover
    {
        background-color: #1f88d6 ;
    }
}
.tooltip1 {
  position: relative;
 .tooltiptext {
  visibility: hidden;
      width: 120px;
    background-color: #b3b3b3;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 80%;
    left: 39%;
    margin-left: -60px;

}
}



.tooltip1:hover .tooltiptext {
  visibility: visible;
}
.d-down
{
  margin-top: -17px;
  margin-right: 24px;
  height: 520px;
  overflow: auto;
}
</style>
