import {
    GET_NOTICE_SOURCES,
    GET_NOTICE_COUNT,
    GET_NOTICE_PER_MONTH,
    GET_ACTIVE_USERS,
} from "../actions.type";
import {
    NOTICE_SOURCES
} from "../mutations.type";
import { post } from "@/api/service";

const state = {
    errors: null,
    accountTypes: '',
    noticeSources: []
};

const getters = {
    noticeSources(state) {
        return state.noticeSources;
    },
};

const actions = {
    [GET_NOTICE_SOURCES](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/getNoticeSources", body)
                .then((data) => {
                    if (data.status == 'failure') {
                        reject(data)
                    } else {
                        resolve(data);
                        if (data) { context.commit(NOTICE_SOURCES, data); }
                    }
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [GET_NOTICE_COUNT](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/noticesCount", body)
                .then((data) => {
                    checkStatus(data,resolve,reject)
                
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [GET_NOTICE_PER_MONTH](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/getNoticesPerMonth", body)
                .then((data) => {
                   checkStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },
    [GET_ACTIVE_USERS](context, body) {
        return new Promise((resolve, reject) => {
            post("v2/activeUsersPercent", body)
                .then((data) => {
                    checkStatus(data,resolve,reject)
                })
                .catch((response) => {
                    reject(response)
                });
        })
    },

};

const mutations = {
    [NOTICE_SOURCES](state, sources) {
        state.noticeSources = sources;
    },
}
function checkStatus(data,resolve,reject)
{
    if (data.status == 'failure') {
        reject(data)
    } else
        resolve(data);
}
export default {
    state,
    actions,
    mutations,
    getters
};