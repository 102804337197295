<template>
  <div id="wrapper">
    <Loading
      :active="isLoading"
    ></Loading>
    <left-drawer />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Header />
        <div class="container-fluid h-100">
          <h3 class="text-dark mb-4">Change Password</h3>
          <div class="row">
            <div class="col d-flex justify-content-center">
              <div class="card w-50 p-3">
                <div class="p-5">
                  <div class="text-center"></div>
                  <form class="user" @submit.prevent="submitForm">
                    <div class="form-group">
                      <input
                        class="form-control form-control-user"
                        type="password"
                        name="oldPassword"
                        maxlength="20"
                        placeholder="Old password"
                        v-model="oldPassword"
                        @change="onChange"
                      />
                      <p>
                        <small
                          class="text-danger"
                          v-if="!oldPassword && submitted"
                          >Old password is required</small
                        >
                      </p>
                    </div>
                    <div class="form-group">
                      <input
                        class="form-control form-control-user"
                        type="password"
                        name="password"
                        placeholder="New password"
                        maxlength="20"
                        @change="onChange"
                        v-model="password"
                      />
                      <p>
                        <small class="text-danger" v-if="!password && submitted"
                          >Valid password is required</small
                        >
                      </p>
                    </div>
                    <div class="form-group">
                      <input
                        class="form-control form-control-user"
                        type="password"
                        name="confirmPassword"
                        maxlength="20"
                        placeholder="Confirm Password"
                        @change="onChange"
                        v-model="confirmPassword"
                      />
                      <p>
                        <small
                          class="text-danger"
                          v-if="!confirmPassword && submitted"
                          >ConfirmPassword is required</small
                        >
                      </p>
                    </div>

                    <button
                      class="btn theme-btn btn-block text-white btn-user mx-0"
                      v-on:click="submitPasswordChange"
                    >
                      Change Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import LeftDrawer from "./LeftDrawer.vue";
import Footer from "./Footer";
import { CHANGE_PASSWORD } from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "ChangePassword",
  components: {
    Header,
    LeftDrawer,
    Footer,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      oldPassword: null,
      password: null,
      confirmPassword: null,
      submitted: false,
    };
  },
  methods: {
    submitPasswordChange() {
      this.isLoading = true;
      this.submitted = true;
      if (!this.password || !this.confirmPassword || !this.oldPassword) {
        this.isLoading = false;
      } else {
        // Password match checking
        if (this.password == this.confirmPassword) {
//Your password must contain at least one number and 
//      one uppercase and lowercase letter and 
//      one special character, and 
//      at least 8 or more characters and
//      no space allowed
          if(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this.password.trim()) )
          {
            this.onChangePassword(this.password, this.oldPassword)
          }
          else
          {
            this.isLoading = false;
            this.$toast.show(
              "Your password must contain at least 8 characters, one number, one uppercase letter, one lowercase letter, and one special character. No space is allowed."
              , {
                  type: "error ",
                  position: "top-right",
                })
          }
        } else {
          this.isLoading = false;
          this.confirmPassword = "";
          this.$toast.show("Password and Confirm Password must be the same", {
            type: "error ",
            position: "top-right",
          });
        }
      }
    },
    onChange(e) {
      if (e.target.name == "oldPassword") {
        this.oldPassword = e.target.value;
      } else if (e.target.name == "password") {
        this.password = e.target.value;
      } else if (e.target.name == "confirmPassword") {
        this.confirmPassword = e.target.value;
      }
    },
    onChangePassword(newPassword, oldPassword) {
      const body = {
        userId: this.$cookie.getCookie("COMPANY_ID"),
        oldPassword: oldPassword,
        newPassword: newPassword,
      };

      this.$store
        .dispatch(CHANGE_PASSWORD, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.submitted = false;
            this.$router.push({ name: "Dashboard" });
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
            this.$toast.show(err.message, {
              type: "error ",
              position: "top-right",
            });
        });

      (this.password = ""),
        (this.oldPassword = ""),
        (this.confirmPassword = "");
    },
  },
};
</script>
