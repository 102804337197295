<template>
  <div id="new-user-section">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <div class="form-row">
      <div class="col">
        <label>Plan Name</label>
        <input
          type="text"
          placeholder="Plan Name (Max : 22 Characters)"
          class="form-control"
          name="planName"
          v-model="planName"
          @change="onChange"
          maxlength="22"
          required
        />
        <p>
          <small class="text-danger" v-if="!planName && submitted"
            >Plan Name is required</small
          >
        </p>
      </div>
       <div class="col">
        <label>Plan Price</label>
        <input
          type="text"
          placeholder="Price"
          class="form-control"
          name="price"
          v-model="price"
          @change="onChange"
          required
          :disabled="edit"
        />
        <p>
          <small class="text-danger" v-if="!edit && !price && submitted"
            >Price is required</small
          >
        </p>
      </div>
      <div class="col">
        <label>Currency</label>
        
        <select
            id="selectCompany"
            class="form-control"
            v-model="currency"
            :disabled="edit"
          >
        <option :selected="true" disabled
        >Select Currency</option
        >
        <!-- v-bind:key="currency.companyId" -->
        <option
          v-for="currency in currencies"
          v-bind:key="currency"
          v-bind:value="currency"
        >
          {{ currency.toUpperCase() }}
        </option>
        </select>
        <p>
          <small class="text-danger" v-if="!currency && submitted"
            >currency is required</small
          >
        </p>
      </div>
    </div>
    <div class="form-row">
     <div class="col">
       <label>Attachment Count</label>
        <input
          type="text"
          placeholder="Attachment Count"
          name="attachmentCount"
          v-model="attachmentCount"
          @change="onChange"
          class="form-control"
          required
        />
        <p>
          <small class="text-danger" v-if="!attachmentCount && submitted"
            >Attachment Count is required</small
          >
        </p>
      </div>
      <div class="col">
        <label>User Count</label>
        <input
          type="text"
          placeholder="User Count"
          name="userCount"
          v-model="userCount"
          @change="onChange"
          class="form-control"
          required
        />
        <p>
          <small class="text-danger" v-if="!userCount && submitted"
            >User Count is required</small
          >
        </p>
      </div>
      <div class="col">
         <label>Notice Count</label>
        <input
          type="text"
          placeholder="Notice Count"
          name="noticeCount"
          v-model="noticeCount"
          @change="onChange"
          class="form-control"
          required
        />
        <p>
          <small class="text-danger" v-if="!noticeCount && submitted"
            >Notice Count is required</small
          >
        </p>
      </div>

      <div class="col">
         <label>Delivery Accounts</label>
        <input
          type="text"
          placeholder="Delivery Accounts"
          name="deliveryAccounts"
          v-model="deliveryAccounts"
          @change="onChange"
          class="form-control"
        />
        <p>
          <small class="text-danger" v-if="!deliveryAccounts && submitted"
            >DeliveryAccounts is required</small
          >
        </p>
      </div>
      <div class="col">
         <label>Trial Period</label>
        <input
          type="text"
          placeholder="Trial Period"
          name="trialPeriod"
          v-model="trial"
          @change="onChange"
          class="form-control"
        />
        <!-- <p>
          <small class="text-danger" v-if="!deliveryAccounts && submitted"
            >DeliveryAccounts is required</small
          >
        </p> -->
      </div>
    </div>
    <div class="form-row">
     <div class="form-check col">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="readStatusConfirmation"
          name="readStatusConfirmation"
          checked=""
          disabled
        />
        <label class="form-check-label" for="formCheck-7" style="padding: 3px"
          >Read Status Confirmation</label
        >
      </div>  
          <div class="form-check col">
        <input
          class="form-check-input"
          type="checkbox"
          name="noticePreview"
          v-model="noticePreview"
          checked=""
          disabled
        />
        <label class="form-check-label" for="formCheck-7" style="padding: 3px"
          >Notice Preview</label
        >
      </div>
       <div class="form-check col">
        <input
          class="form-check-input"
          type="checkbox"
          name="multipleAttachment"
          v-model="multipleAttachment"
          disabled
          checked=""
        />
        <label class="form-check-label" for="formCheck-7" style="padding: 3px"
          >Multiple Attachment</label
        >
      </div>
       <div class="form-check col">
        <input
          class="form-check-input"
          type="checkbox"
          name="downloadNotice"
          v-model="downloadNotice"
          disabled
          checked=""
        />
        <label class="form-check-label" for="formCheck-7" style="padding: 3px"
          >Download Notice</label
        >
      </div>
    </div>
    <div class="form-row">
      <div class="form-check col">
        <input
          class="form-check-input"
          type="checkbox"
          name="deliveryConfirmation"
          v-model="deliveryConfirmation"
          disabled
          checked=""
        />
        <label class="form-check-label" for="formCheck-7" style="padding: 3px"
          >Delivery Confirmation</label
        >
      </div>
     
    
    
      <div class="form-check col">
        <input
          class="form-check-input"
          type="checkbox"
          name="sendAndRecieveNotice"
          v-model="sendAndRecieveNotice"
          disabled
          checked=""
        /><label class="form-check-label" for="formCheck-7" style="padding: 3px"
          >Send And Receive Notice</label
        >
      </div>
    </div>
    <!-- btn-primary -->
    <button
      class="btn theme-btn text-light btn-sm"
      type="submit"
      style="margin-top: 20px"
      v-on:click="submitClick"
    >
      Save
    </button>
  </div>
</template>
<script>
import Loading from "vue3-loading-overlay";
import { ADD_SUBSCRIPTIONS_TIER, EDIT_SUBSCRIPTIONS_TIER,LIST_SUBSCRIPTIONS_TIER } from "@/store/actions.type";
export default {
  name: "EditSubscriptionTiers",
  components: {
    Loading,
  },
  created() {
    if (this.edit) {    
    this.id = this.user.id
    this.planName = this.user.planName
    this.attachmentCount = this.user.attachmentCount
    this.price = Number(this.user.price) /100
    this.userCount = this.user.userCount
    this.currency = this.user.currency
    this.noticeCount = this.user.noticeCount
    this.readStatusConfirmation = this.user.readStatusConfirmation
    this.noticePreview = this.user.noticePreview
    this.multipleAttachment = this.user.multipleAttachment
    this.downloadNotice = this.user.downloadNotice
    this.sendAndRecieveNotice = this.user.sendAndRecieveNotice
    this.deliveryConfirmation = this.user.deliveryConfirmation
    this.deliveryAccounts = this.user.deliveryAccounts
    this.trial = this.user.trialPeriod
    }
 },

  data() {
    return {
      isLoading: false,
      submitClicked: false,
      fullPage: true,
      id:"",
      planName: "",
      attachmentCount: "",
      userCount: "",
      noticeCount: "",
      price: "",
      currency: "Select Currency",
      currencies:['usd'],
      deliveryConfirmation: true,
      deliveryAccounts: "",
      readStatusConfirmation: true,
      noticePreview: true,
      multipleAttachment: true,
      downloadNotice: true,
      sendAndRecieveNotice: true,
      trial:"",
      submitted: false,
    };
  },
  props: ["user", "edit"],
  methods: {
    onChange(e) {
      if (e.target.name == "planName") {
        this.planName = e.target.value;
      } else if (e.target.name == "price") {
        this.price = e.target.value;
      } else if (e.target.name == "userCount") {
        this.userCount = e.target.value;
      } else if (e.target.name == "currency") {
        this.currency = e.target.value;
      } else if (e.target.name == "noticeCount") {
        this.noticeCount = e.target.value;
      } else if (e.target.name == "readStatusConfirmation") {
        this.readStatusConfirmation = e.target.value;
      } else if (e.target.name == "noticePreview") {
        this.noticePreview = e.target.value;
      } else if (e.target.name == "multipleAttachment") {
        this.multipleAttachment = e.target.value;
      } else if (e.target.name == "downloadNotice") {
        this.downloadNotice = e.target.value;
      } else if (e.target.name == "sendAndRecieveNotice") {
        this.sendAndRecieveNotice = e.target.value;
      }else if (e.target.name == "deliveryConfirmation") {
        this.deliveryConfirmation = e.target.value;
      } else if (e.target.name == "deliveryAccounts") {
        this.deliveryAccounts = e.target.value;
      } else if (e.target.name == 'trialPeriod')
      {
        this.trial = e.target.value
      }
    },
    submitClick(){
      this.submitted = true
      this.Loading = true
      if (!this.planName) {
        this.Loading = false
      }else{
        if(this.currency == "Select Currency")
        {
          this.$toast.show("Please Select Currency!", {
              type: "error ",
              position: "top-right",
            });
        }
        else
        {
         if(
          this.validationChecks(this.price,"Price") && this.validationChecks(this.attachmentCount,"Attachment Count") 
          && this.validationChecks(this.userCount,"User Count") && this.validationChecks(this.noticeCount,"Notice Count") &&
          this.validationChecks(this.deliveryAccounts,"Delivery Accounts")
         )
         {
          this.updateTier()
         }
        }
      }
    },
    validationChecks(value,name)
    {
      if(/^[0-9]+$/.test(value) )
      {
        return true
      }
      else
      {
        this.$toast.show(`${name} is invalid!`, {
              type: "error ",
              position: "top-right",
            });
        return false
      }
    },
    updateTier(){
      let body ={
        product_id: this.user.product_id ? this.user.product_id : '',
        subId: this.id,
        planName:this.planName,
        attachmentCount:this.attachmentCount,
        userCount:this.userCount,
        noticeCount:this.noticeCount,
        price:Number(this.price) * 100,
        currency:this.currency,
        deliveryConfirmation:this.deliveryConfirmation,
        deliveryAccounts: this.deliveryAccounts,
        readStatusConfirmation:this.readStatusConfirmation,
        noticePreview:this.noticePreview,
        multipleAttachment:this.multipleAttachment,
        downloadNotice:this.downloadNotice,
        sendAndRecieveNotice:this.sendAndRecieveNotice,
        trialperiod: this.trial ? this.trial : 0
      };
      this.$store
        .dispatch(this.edit?EDIT_SUBSCRIPTIONS_TIER:ADD_SUBSCRIPTIONS_TIER, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
            this.$parent.$emit("colse-modal");
             this.$store
              .dispatch(LIST_SUBSCRIPTIONS_TIER)
              .then((data) => {
                this.isLoading = false;
                if (data.status == "failure") {
                  this.$toast.show(data.message, {
                    type: "error ",
                    position: "top-right",
                  });
                } 
              })
              .catch((err) => {
                this.$toast.show(err.message, {
                    type: "error ",
                    position: "top-right",
                  });
                this.isLoading = false;
              });
          }
        })
        .catch((err) => {
          this.$toast.show(err.message, {
                    type: "error ",
                    position: "top-right",
                  });
          this.isLoading = false;
        });
    }
  },
};
</script>