import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import { store } from './store'
import ApiService from "./api/api.service";
import { VuelidatePlugin } from '@vuelidate/core'
import VueApexCharts from "vue3-apexcharts";
import './assets/fonts/fontawesome-all.min.css';
import './assets/fonts/font-awesome.min.css';
import './assets/fonts/ionicons.min.css';
import './assets/fonts/line-awesome.min.css';
import './assets/fonts/material-icons.min.css';
import './assets/fonts/fontawesome5-overrides.min.css';
import './assets/css/styles.min.css';
import Toaster from '@meforma/vue-toaster';
import moment from 'moment';
import { VueCookieNext } from 'vue-cookie-next'
import { firebase } from '@firebase/app'
import 'firebase/messaging'
import './registerServiceWorker'
import { jsPDF } from "jspdf";
import PrimeVue from 'primevue/config';
import ClickOutside from './click-outside';




const firebaseConfig = {
    apiKey: "AIzaSyB32xi1xMt4gYG8f6ttYZ7JMMKWX_w5inQ",
    authDomain: "one-notice678.firebaseapp.com",
    projectId: "one-notice678",
    storageBucket: "one-notice678.appspot.com",
    messagingSenderId: "734603282717",
    appId: "1:734603282717:web:ef0fbd9ea6b48bb4bbb9f4",
    measurementId: "G-0H4GPXPDRC"
};


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)

} else {
    firebase.app(); // if already initialized, use that one
}

const app = createApp(App)
app.use(VueAxios, axios);
app.use(store);
app.use(router);
app.use(Toaster);
app.use(moment)
app.use(jsPDF)
app.use(VuelidatePlugin);
app.use(VueApexCharts);
app.use(VueCookieNext)
app.use(PrimeVue);

app.directive('click-outside', ClickOutside);

app.mount('#app');
ApiService.init();
// set default config
VueCookieNext.config({ expire: '7d' })