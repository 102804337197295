export const CHECK_AUTH = "checkAuth";
export const LOGIN = "login";
//user store changes
export const SET_USER = "setUser";
export const SET_DELVERY_ACCOUNT_ITEM = "setDeliveryAccountItem";
export const SET_ALL_DELIVERY_ACCOUNT_ITEM = "setAllDeliveryAccountItem";
export const FORGOT = "forgot";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const VALIDATE = "validateAccount";
export const GET_ACCOUNT_TYPES = 'getAccountTypes';
export const CREATE_NEW_ACCOUNT = "createNewAccount";
export const RESET = "resetPassword";
export const GET_DELIVERY_ACCOUNTS = "getDeliveryAccounts";
export const SAVE_DELIVERY_ACCOUNTS = "saveDeliveryAccounts";
export const UPDATE_CONTACT = "updateContact";
export const CREATE_CORPORATE_USER = "createCorporateUser";
export const GET_PERMISSIONS = "getPermissions";
export const LISTUSER_PERMISSIONS = "list_userpermissions";
export const GET_CORPORATE_USERS = "getCorporateUsers";
export const EDIT_CORPORATE_USER = "editCorporateUser";
export const UPLOAD_PROFILE_IMAGE = "uploadProfileImage";
export const CHANGE_PASSWORD = "changePassword";
export const SEND_NOTICE = "sendNotice";
export const CHECK_ACCOUNT = "checkAccount"
export const GET_DELIVERY_ACCOUNT = "deliveryAccount"
export const GET_ONE_BOX_LIST = "getOneboxList"
export const DOWNLOAD_NOTICE = "downloadNotice"
export const SEARCH_NOTICE = "searchNotice"
export const GET_NOTICE_SOURCES = "getNoticeSource"
export const GET_NOTICE_COUNT = "getNoticeCount"
export const GET_NOTICE_PER_MONTH = "getNoticeCountPerMonth"
export const GET_ACTIVE_USERS = "getActiveUsers"
export const GET_ACCOUNTS = "getAccounts"
export const ACTIVATE_DEACTIVATE = "activateDeactive"
export const RESET_USER_PASSWORD = "resetUserPassword"
export const GET_USER_DETAILS = "getUserDetails"
export const LIST_SUBSCRIPTIONS_TIER = "ListSubscriptionTier"
export const ADD_SUBSCRIPTIONS_TIER = "AddSubscriptionTier"
export const EDIT_SUBSCRIPTIONS_TIER = "EditSubscriptionTier"
export const UPDATE_ACCOUNT_LIMITS = "updateAccountLimits"
export const UPDATE_ACCOUNT_STATUS = "updateAccountStatus"
export const LIST_PAYMENTS = "listPayment"
export const LIST_NOTIFICATIONS = "listNotifications"
export const UPDATE_NOTIFICATION = "changeNotificationRead"
export const ACTIVITY_LOG = "activityLogs"
export const GET_DASHBOARD_ONE_BOX = "dashBoardOneBox"
export const GET_IMAGE = "getImage"
export const LIST_DELIVERY_ACCOUNTS = "listDeliveryAccounts"
export const SEARCH_DELIVERY_ACCOUNT = "searchDeliveryAccount"
export const ONE_BOX_NOTICE_DETAILS = "oneBoxfiles"
export const ADD_FILE_READ = "add_fileread"
export const DOWNLOAD_SINGLE_NOTICE = "singledownloadNotice"
export const ADD_FILE_DOWNLOAD = "add_filedownload"
export const BOOKMARK_NOTICE = "bookmarkNotice"
export const VIEW_SUBSCRIPTION = "viewSubscription"
export const TWO_FACTOR_AUTHENTICATION = "twoFactorAuthentication"
export const VERIFY_OTP = "verifyOtp"
export const GENETATE_EMAL_OTP = "generateEmailOtp"
export const GET_COMPANY_TWO_FACTOR = "get_companytwofactor"
export const UPDATE_2FA = "update2FA"
export const LIST_COUPONS = "listCoupons"
export const CREATE_COUPON = "createCoupon"
export const DELETE_COUPON = "deleteCoupon"
export const ADD_PROMO_CODE = "addPromoCode"
export const UNREAD_NOTIFICATION_COUNT = "unreadNotificationCount"
export const GET_CONTACT_DETAILS = "getContactDetails"
export const SET_TOKEN = "setToken"