<template>
  <div class="bg-gradient-primary backdrop">
    <div class="container">
      <Loading
        :active="isLoading"
        :can-cancel="true"
        
        :is-full-page="fullPage"
      ></Loading>
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-12 col-xl-10">
          <div class="card shadow-lg o-hidden border-0 my-5">
            <div class="card-body p-0">
              <div class="row justify-content-center">
                <!-- <div class="col-lg-5 d-none d-lg-flex back"> -->
                  <!-- <div class="image-box">
                    <img
                      class="image-left"
                      src="../assets/img/oneNotice/oneNoticeLogo.png"
                    />
                  </div> -->
                  <!-- <div class="flex-grow-1 bg-login-image logo" :style="{ backgroundImage: 'url(' + require('@/assets/img/oneNotice/oneNoticeLogo.png') + ')' }"/> -->
                <!-- </div> -->
                <div class="col-lg-7">
                  <div class="image-box">
                    <img
                      class="img-fluid"
                      src="../assets/img/oneNotice/oneNoticeLogo.png"
                      alt="oneNoticeLogo"
                    />
                  </div>
                  <div class="p-5">
                    <div class="text-center">
                      <h4 class="text-dark mb-4">Create Password</h4>
                    </div>
                    <form class="user" @submit.prevent="submitForm">
                      <div class="form-group">
                        <input
                          class="form-control form-control-user"
                          v-bind:class="{
                            'is-invalid': !password && submitted,
                          }"
                          type="password"
                          maxlength="20"
                          aria-describedby="emailHelp"
                          placeholder="Enter new password"
                          v-model.trim="password"
                        />
                        <p>
                          <small
                            class="text-danger"
                            v-if="!password && submitted"
                            >Valid password is required</small
                          >
                        </p>
                      </div>
                      <div class="form-group">
                        <input
                          class="form-control form-control-user"
                          v-bind:class="{
                            'is-invalid': !confirmPassword && submitted,
                          }"
                          type="password"
                          maxlength="20"
                          placeholder="Confirm Password"
                          v-model.trim="confirmPassword"
                        />
                        <p>
                          <small
                            class="text-danger"
                            v-if="!confirmPassword && submitted"
                            >ConfirmPassword is required</small
                          >
                        </p>
                      </div>
<!-- btn-primary -->
                      <button
                        class="btn theme-btn btn-block text-white btn-user mx-0"
                        type="submit"
                        :disabled="submitStatus === 'PENDING'"
                      >
                        Reset Password
                      </button>

                      <hr />
                      <hr />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import { RESET } from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "Login",
  components: {
    Loading,
  },
  methods: {
   
    handleBlur(key) {
      this.$v[key].$dirty = true;
    },
    submitForm() {
      this.isLoading = true;
      this.submitted = true;
      if (!this.password || !this.confirmPassword) {
        this.submitStatus = "ERROR";
        this.isLoading = false;
      } else {
        // Password match checking
        if (this.password == this.confirmPassword) {
          if(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this.password.trim()) )
          {
          this.onResetPassword(this.password, this.token)
          }
          else
          {
            this.isLoading = false;
            this.$toast.show(
              "Your password must contain at least 8 characters, one number, one uppercase letter, one lowercase letter, and one special character. No space is allowed."
              , {
                  type: "error ",
                  position: "top-right",
                })
          }
        } else {
          this.isLoading = false;
          this.$toast.show("Password and Confirm Password must be the same", {
            type: "error ",
            position: "top-right",
          });
        }
      }
    },
    onChange(e) {
      //this.submitted = false;
      if (e.target.name == "password") {
        this.password = e.target.value;
      } else if (e.target.name == "confirmPassword") {
        this.confirmPassword = e.target.value;
      }
    },
    onResetPassword(newPassword, token) {
      this.$store
        .dispatch(RESET, { newPassword, token, repeatPassword: newPassword })
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$router.push({ name: "Login" });
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
            this.$toast.show(err.message, {
              type: "error ",
              position: "top-right",
            });
        });
    },
  },
  computed: {
    // users() {
    //   return this.$store.state.user;
    // },
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      password: null,
      confirmPassword: null,
      token: null,
      submitStatus: null,
    };
  },
  beforeMount() {
    this.token = this.$route.query.key;
  },
  validations: {
    confirmPassword: { required },
    password: { required },
  },
};
</script>

<style scoped></style>
