<template>
  <div id="new-user-section">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <h2 class="text-primary card-subtitle small font-weight-bold mb-3">
      Reset User Password
    </h2>
    <div class="form-row">
      <div class="col">
        <input
          type="password"
          maxlength="20"
          placeholder="New Password"
          class="form-control"
          name="newPassword"
          v-model="newPassword"
          @change="onChange"
        />
        <p>
          <small class="text-danger" v-if="submitted && !newPassword"
            >New Password is required</small
          >
        </p>
      </div>
      <div class="col">
        <input
          type="password"
          maxlength="20"
          placeholder="Confirm Password"
          name="confirmPassword"
          v-model="confirmPassword"
          @change="onChange"
          class="form-control"
        />
        <p>
          <small class="text-danger" v-if="submitted && !confirmPassword"
            >Confirm Password required</small
          >
        </p>
      </div>
      
      <button
        class="btn theme-btn text-light btn-sm"
        type="submit"
        style="margin-top: 1px;height:35px;"
        v-on:click="submitclick"
      >
        Save&nbsp;Settings
      </button>
    </div>
  </div>
</template>
<script>
import { RESET_USER_PASSWORD } from "@/store/actions.type";
import Loading from "vue3-loading-overlay";
export default {
  name: "ResetPassword",
  components: {
    Loading,
  },
  props: ["user","userId","fromSuper","selectedUser"],
  created() {
    if (this.user!=undefined) {
     this.selectedUserId = this.user.id
    }
    this.corpUserId = this.userId;
    
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      newPassword: "",
      confirmPassword: "",
      selectedUserId: "",
      corpUserId:"",
      submitted: false,
    };
  },
  methods: {
    onChange(e) {
      if (e.target.name == "newPassord") {
        this.newPassword = e.target.value;
      } else if (e.target.name == "confirmPassword") {
        this.confirmPassword = e.target.value;
      }
    },

    submitclick() {
      this.isLoading = true;
      this.submitted = true;
      if (!this.newPassword || !this.confirmPassword) {
        this.isLoading = false;
      } else {
        
            if(this.newPassword == this.confirmPassword)
            {
              this.resetUserPassword();
            }
            else
            {
              this.isLoading = false;
              this.$toast.show("Passwords do not Match!", {
              type: "error ",
              position: "top-right",
            });
            }
      }
    },
    resetUserPassword() {
      let id;
      if(this.fromSuper)
      {
        if(this.selectedUser)
        {
          id =this.selectedUser
        }
      }
      else
      {
        if(this.user)
        {
          id = this.user.id
        }
        else
        {
          id = this.userId
        }
      }
      let body = {
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
        userId: id
        
      };
      
      this.$store
        .dispatch(RESET_USER_PASSWORD, body)
        .then((data) => {
          this.isLoading = false;
          if (data.status == "failure") {
            this.$toast.show(data.message, {
              type: "error ",
              position: "top-right",
            });
          } else {
            this.$toast.show(data.message, {
              type: "success ",
              position: "top-right",
            });
            this.$parent.$emit("colse-modal");
          }
        })
        .catch((error) => {
          this.$toast.show(error.message, {
            type: "error ",
            position: "top-right",
          });
          this.isLoading = false;
        });
    },
  },
};
</script>
